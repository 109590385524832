import React from 'react';



const featureCards = [
	{icon:"bi-truck",title:"Load Dispatching",description:"Create and assign loads from web or mobile."},
	{icon:"bi-ticket",title:"Load Ticketing",description:"Operators can create eTickets when selling to third party transporters."},
	{icon:"bi-phone",title:"Driver Dispatch App",description:"Drivers can accept loads, notify of pickup and dropoff and more. There is also the option for drivers to create their own loads to deliver."},
	{icon:"bi-pen",title:"Signature Collection",description:"Collect sigantures when materials are picked up or dropped off."},
	{icon:"bi-envelope",title:"Automatic Email Receipts",description:"Automatically send PDF receipts with your logo to customers for faster billing."},
	{icon:"bi-currency-dollar",title:"Pricing Management",description:"Dispatchers and managers can easily price dispatched load and tickets to send to accounting."},
	{icon:"bi-table",title:"Export to Excel",description:"Download records to excel for your records or to send to customers."},
	{icon:"bi-camera",title:"Photos",description:"Take photos of tickets, bills or delivered materials."},
	]

const KeyFeatures =  ({description}) =>  
    <div className="container mt-4 mb-4">
      <h2 className="text-center mb-4">Avoid the hassles of managing a materials dispatch</h2>
      {description && <div className="offset-2 col-8 p-0 pb-4">{description}</div>}
      <div className="row row-cols-1 row-cols-md-4 g-4 ms-0 me-0">
        {featureCards.map(({title,description,icon,link}) => <Card key={title} title={title} description={description} icon={icon} link={link} />)}
      </div>
    </div>


const Card = ({title,description,icon,link}) => {
	return (
		<div className="col text-center" >
			<div className="card h-100 mx-auto" >
			  <div className="card-body text-center">
			  	<i className={icon} style={{fontSize:40}}></i>
			    <h5 className="card-title">{title}</h5>
			    <p className="card-text">{description}</p>
			    
			  </div>
			</div>
		</div>

	);
}

export default KeyFeatures;