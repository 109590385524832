import { Fragment,useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import DashboardImage from '../images/DispatchDashboard-v2.png';
import DispatchKeyFeatures from '../components/DispatchKeyFeatures';

//import Button from './SignupButton';
import Card from './SignupCard';
import Spinner from '../components/FullScreenSpinner';

import KJTree from '../images/KJ_Logo.png';

import {
	Form,
	Row,
	Col,
	Alert,
    Card as TestimonialCard, 
	Button
} from "react-bootstrap";

import {useMutation} from "@apollo/client";
import Signup from "../graphql/Signup";

export const isEmail = (email) => {
	const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

	if (!email)
		return false;

  	if (email.match(validRegex)) 
  		return true;

  	return false;

}

export default function SignupForm() {
	const navigate = useNavigate();
	const site = sessionStorage.getItem("site")
	const [formFieldValues,setFormFieldValues] = useState({});
	const [errors,setErrors] = useState({});

    useEffect(() => {
   		if(window._hsq) {
		    window._hsq.push(['setPath', "/signup"]);
		    window._hsq.push(['trackPageView']);

   		}
  
       
    }, []);

	// run signup
	const [signup,{loading}] = useMutation(Signup,{
		variables: {...formFieldValues,acceptTerms:new Date()},
		onError: error => {
			goToTop();
	        const message = error?.graphQLErrors?.length > 0 ? error.graphQLErrors[0].message : "There was a problem connecting to Fleet Command. Please try again."
	        if (message.indexOf("That email is already in use") > -1) {
	        	setErrors({network:"It looks like you already have an account. If you would like to activate your free dispatch trial, login, click your name in the menu and select 'Add Dispatch Trial'."})
	        }
	        else {
	        	setErrors({network:message})
	        }
			
		},
		onCompleted: result => {
			  // add authtoken to browser
			  localStorage.setItem("Authorization",result.signup.token);
			  sessionStorage.setItem("NewBusiness",true)
			  // go to next step
			  navigate("/signup/product-selection");
		}
	})

	const handleChange = ({field,e}) => {
		setFormFieldValues({...formFieldValues,[field]:e.target.value});
		setErrors({...errors,[field]:false})
	}

	const handleSignUp = (e) => {
		e.preventDefault();
		e.stopPropagation();


		const {firstName,lastName,email,company,password} = formFieldValues;

		let validationErrors = {};
		// validate fields
		if (!firstName || firstName.trim().length === 0) {
			validationErrors.firstName = true;
		}
		if (!lastName || lastName.trim().length === 0) {
			validationErrors.lastName = true;
		}
		if (!company || company.trim().length === 0) {
			validationErrors.companyName = true;
		}
		if (!email || email.trim().length === 0 || !isEmail(email)) {
			validationErrors.email = true;
		}
		if (!password || password.trim().length < 8) {
			validationErrors.password = true;
		}

		setErrors(validationErrors);

		if (Object.keys(validationErrors).length === 0) {
			signup();
		}

	}

	const goToTop = () => {
	  window.scrollTo({
	    top: 0,
	    behavior: 'smooth',
	  });
	};

	return (
        <Fragment>
            
            <h1 className="mb-4">Streamlined Dispatching</h1>
		<Row className='mt-4'>
			<Col xs={12} sm={12} md={{span:6,offset:1}}>
				<div className='text-center mb-4'>
                    <img src={DashboardImage} style={{width:"80%"}} alt="Web and mobile dispatch dashboards" />
                </div>
                <div>
                    <h2 className="text-start">Our customers no longer struggle with...</h2>
                    
                        <ul className='mx-auto'>
                            <li className="mb-2">Lost paperwork</li>
                            <li className="mb-2">Communicating to drivers</li>
                            <li className="mb-2">Missed billing</li>
                        </ul>
                    
                    

				</div>

			</Col>
			<Col xs={12} sm={12} md={5}>
				<Card>
					{loading && <Spinner />}
					{errors.network && <Alert>{errors.network}</Alert>}
					<h2>Let's get some info to create an account</h2>
					<Form id="signup-form" className="text-start" onSubmit={handleSignUp}>
						<Row>
							<Col sm={12} md={6}>
								<Form.Group>
									<Form.Label>*First Name</Form.Label>
									<Form.Control onChange={e => handleChange({field:"firstName",e})}></Form.Control>
									{errors.firstName && <Form.Text style={{color:"red",fontSize:16}}>Please enter a first name</Form.Text>}
								</Form.Group>
							</Col>
							<Col sm={12} md={6}>
								<Form.Group>
									<Form.Label>*Last Name</Form.Label>
									<Form.Control onChange={e => handleChange({field:"lastName",e})}></Form.Control>
									{errors.lastName && <Form.Text style={{color:"red",fontSize:16}}>Please enter a last name</Form.Text>}
								</Form.Group>
							</Col>
						</Row>
						<Form.Group>
							<Form.Label>*Company Name</Form.Label>
							<Form.Control  onChange={e => handleChange({field:"company",e})}></Form.Control>
							{errors.companyName && <Form.Text style={{color:"red",fontSize:16}}>Please enter a company name</Form.Text>}
						</Form.Group>
						<Form.Group>
							<Form.Label>*Email</Form.Label>
							<Form.Control type="email" onChange={e => handleChange({field:"email",e})}></Form.Control>
							{errors.email && <Form.Text style={{color:"red",fontSize:16}}>Please enter a valid email</Form.Text>}
						</Form.Group>
						<Form.Group>
							<Form.Label>*Password</Form.Label>
							<Form.Control type="password" onChange={e => handleChange({field:"password",e})}></Form.Control>
							{errors.password && <Form.Text style={{color:"red",fontSize:16}}>Please enter a password that is at least 8 characters long</Form.Text>}
						</Form.Group>
						<Form.Group>
							<Form.Label>Phone</Form.Label>
							<Form.Control type="tel" onChange={e => handleChange({field:"phone",e})}></Form.Control>
							
						</Form.Group>
						<Form.Group>
							<Form.Label>How did you find us?</Form.Label>
							<Form.Control onChange={e => handleChange({field:"referredBy",e})}></Form.Control>
							<Form.Text>If someone referred you, we'd love to thank them.</Form.Text>
						</Form.Group>

					      <Form.Group >
					        <Form.Label>Tell us what you hope to achieve with {!site ? "Fleet Command" : "Fleet Command Dispatch"}.</Form.Label>
					        <Form.Control as="textarea" rows={3} placeholder="One sentence is fine" onChange={e => handleChange({field:"achievement",e})} />
					      </Form.Group>
					      
						<div className="mt-4 w-100 text-center">
							<Button type="submit">Next</Button>
					       	<p className="mt-2"><em>By clicking "Next" you are agreeing to our <a target="_blank" rel="noreferrer" href="https://app.termly.io/document/terms-of-use-for-saas/699c8c34-fe91-48f7-85f5-ced2408e25d6">terms and conditions</a>.</em></p>
						</div>
					</Form>
				</Card>
			</Col>
		</Row>
        <Row>
            <Col>
                <DispatchKeyFeatures />
                <Row>
                    <Col sm={12} md={12}>
                        <div className="flex-1 px-12">
                        <TestimonialCard>
                            <TestimonialCard.Body className='text-center'>
                                <img src={KJTree} alt="K&J Tree Service" />
                                <h3>K&J Tree Service</h3>
                                <p className='card-text fst-italic text-secondary '>We have found that the simplicity of Fleet Command is really what drives us to make full use of its features. Some software brands are too complex, and would take too long to implement. Fleet Command has allowed us to hit the ground running both from an inventory management side and a fleet management side.</p>  

                            </TestimonialCard.Body>
                        </TestimonialCard>
                        </div>
                    </Col>
                </Row>
                <div className='text-center mt-4'>
                    <a href="#top" className="text-center mt-4 mb-4 mx-auto">Back to top</a>
                </div>
                
            </Col>
        </Row>
        </Fragment>
	)
}