import HomePage from "./HomePage";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';


// leaving route management in here for now
import SignupLayout from "./signup/SignupLayout";
//import WelcomeScreen from "./signup/WelcomeScreen";
//import AddAssets from "./signup/AddAssetsScreen";
//import AddPeople from "./signup/AddPeopleScreen";
//import AskAddAssets from "./signup/AskAddAssetsScreen";
//import FinalSetupComplete from "./signup/FinalSetupCompleteScreen";
import InitialSetupComplete from "./signup/InitialSetupCompleteScreen";
import ProductSelectionScreen from "./signup/ProductSelectionScreen";
import SignupFormScreen from "./signup/SignupFormScreen";
import SignupFormScreen2 from "./signup/SignupFormScreen-2";
import SignupFormScreen3 from "./signup/SignupFormScreen-3";


import ForgotPassword from "./auth/ForgotPassword";
import ForgotPasswordComplete from "./auth/ForgotPasswordComplete";
import ResetPassword from "./auth/ResetPassword";


import TVLogin from './auth/tvlogin'


function App() {

 

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/setNewPassword" element={<ResetPassword />} />
        <Route path="/forgot-password-complete" element={<ForgotPasswordComplete />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />

        <Route path="/tvlogin" element={<TVLogin />} />

        <Route path="/signup" element={<SignupLayout />}>
            <Route path="signup-form" element={<SignupFormScreen />} />
            <Route path="product-selection" element={<ProductSelectionScreen />} />
            <Route path="initial-setup-complete" element={<InitialSetupComplete />} />
            <Route index element={<SignupFormScreen />} />
            
        </Route>
        <Route path="/free-trial-signup" element={<SignupLayout />}>
            <Route path="signup-form" element={<SignupFormScreen2 />} />
            <Route path="product-selection" element={<ProductSelectionScreen />} />
            <Route path="initial-setup-complete" element={<InitialSetupComplete />} />
            <Route index element={<SignupFormScreen2 />} />
            
        </Route>
        <Route path="/free-trial-signup-dispatch" element={<SignupLayout title="Dispatch Signup" />}>
            <Route path="signup-form" element={<SignupFormScreen3 />} />
            <Route path="product-selection" element={<ProductSelectionScreen />} />
            <Route path="initial-setup-complete" element={<InitialSetupComplete />} />
            <Route index element={<SignupFormScreen3 />} />
            
        </Route>
        

        {process.env.NODE_ENV !== 'development' && <Route path="*" element={<Navigate replace to="/" />} />}

      </Routes>
      

    </BrowserRouter>
  );
}

export default App;
