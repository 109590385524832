import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import Button from './SignupButton';
import Card from './SignupCard';
import Spinner from '../components/FullScreenSpinner';

import { Form, Alert } from 'react-bootstrap';

import {useMutation} from '@apollo/client';
import SelectServices from '../graphql/SelectServices';

export default function ProductSelection() {
	const navigate = useNavigate();
	const site = sessionStorage.getItem("site")
	const { state:message } = useLocation();
	const [formFieldValues,setFormFieldValues] = useState({});
	const [errors,setErrors] = useState();

	useEffect(() => {
		window.gtag('config', 'AW-729572783');
    	window.gtag('event', 'conversion', { 'send_to': 'AW-729572783/vVbhCOPT15EYEK_L8dsC', 'transaction_id': '' })
    	if (sessionStorage.getItem("site") === "dispatch") {
    		setFormFieldValues({...formFieldValues,dispatch:true});
    	}
    	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

	// run signup
	const [selectServices,{loading}] = useMutation(SelectServices,{
		// headers aren't always updating in client. This ensures the user doesn't get stuck here.
		context:  {
		        headers: {
		            authorization: localStorage.getItem('Authorization')
		        }
		    },
		variables: formFieldValues,
		onError: error => {
			console.log("error",error)
	        const message = error?.graphQLErrors?.length > 0 ? error.graphQLErrors[0].message : "There was a problem connecting to Fleet Command. Please try again."

			setErrors(message)
		},
		onCompleted: result => {
			console.log("result",result)
			  // go to next step
			  navigate("/signup/initial-setup-complete",{state:{services:formFieldValues}});
		}
	})

	const handleChange = ({field,e}) => {
		setFormFieldValues({...formFieldValues,[field]:e.target.checked});
	}

	const handleSelectServices = () => {

		const {shop,dispatch} = formFieldValues;

		let validationErrors = "";
		if (!shop === true && !dispatch) {
			validationErrors = "What services would you like to try?";
		}

		setErrors(validationErrors);

		if (validationErrors.length === 0) {
			selectServices();
		}

	}

	const renderShopSignupForm = () => {
		return (
			<Form className="text-start" onSubmit={e => e.preventDefault()}>
				<Form.Group className="mb-3" controlId="formBasicCheckbox">
					<Form.Check type="checkbox" label="Shop (for maintenance and inventory)" style={{fontWeight: "bold"}} onChange={e => handleChange({field:"shop",e})} />
				</Form.Group>
				<Form.Group className="mb-3" controlId="formBasicCheckbox">
					<Form.Check type="checkbox" checked={formFieldValues?.dispatch || false} label="Dispatch (for deliveries and load tickets)" style={{fontWeight: "bold"}} onChange={e => handleChange({field:"dispatch",e})} />
				</Form.Group>

				<div className="text-center">
					<Button onClick={()=>handleSelectServices()}>
						Next
					</Button>
				</div>
			</Form>
		)
	}

	const renderDispatchSignupForm = () => {
		return (
			<Form className="text-start" onSubmit={e => e.preventDefault()}>
				<p className="text-muted" style={{fontStyle:"italic"}}>Fleet Command Dispatch is part of the Fleet Command family of apps that work together to make managing your fleet easier.</p>
				<p className="text-muted" style={{fontStyle:"italic"}}>Would you be opposed to a free trial of the Fleet Command Shop for repair shops?</p>

				<Form.Group className="mb-3" controlId="formBasicCheckbox">
					<Form.Check type="checkbox" checked={formFieldValues?.dispatch || false} label="Dispatch (for deliveries and load tickets)" style={{fontWeight: "bold"}} onChange={e => handleChange({field:"dispatch",e})} />
				</Form.Group>
				<Form.Group className="mb-3" controlId="formBasicCheckbox">
					<Form.Check type="checkbox" label="I'll try Fleet Command for Shops Too (for maintenance and inventory)" style={{fontWeight: "bold"}} onChange={e => handleChange({field:"shop",e})} />
				</Form.Group>

				<div className="text-center">
					<Button onClick={()=>handleSelectServices()}>
						Next
					</Button>
				</div>
			</Form>
		)
	}

	return (
		<Card>
			
			{loading && <Spinner />}
			{errors && <Alert>{errors}</Alert>}

			<h2>Select your products</h2>
			{message && <Alert variant="info">{message}</Alert>}
			<p>Which Fleet Command products do you want to try out?</p>

			{/* use different messaging based on signup app*/}
			{site === "dispatch" ? renderDispatchSignupForm() : renderShopSignupForm()}

		</Card>
	)
}